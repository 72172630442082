import styled from 'styled-components';
import 'react-ig-feed/dist/index.css';

export const Container = styled.div`
  height: auto;
  z-index: 2;
  position: relative;
  background-size: contain;

  @media screen and (max-width: 640px) {
    background: #feba45;
    background-image: inherit !important;
  }

  .image-bottom {
    position: absolute;
    z-index: 10;
    top: -15%;
    right: 85%;

    img {
      width: 400px;
    }
    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .top-text {
    display: flex;
    justify-content: center;

    .text {
      position: relative;
      p {
        margin-top: 160px;
        font-weight: 900;
        font-family: 'Abril Fatface', cursive;
        color: #281627;
        font-size: 50px;
        letter-spacing: 2px;
        transition: all ease 0.6s;

        @media screen and (max-width: 640px) {
          font-size: 37px;
          width: 100%;
          padding: 0 1.5em;
        }
      }
    }
  }
`;

export const InstagramHolder = styled.div`
  margin-top: 90px;
  padding-bottom: 190px;

  .insta__photos {
    max-width: 110rem;
    margin: 0 auto;
    padding: 0 2rem;
    > div {
      /* justify-content: center; */
    }
  }

  .InstaGrid-image-2:hover {
    opacity: 0.8;
  }

  .MuiGrid-grid-xs-12 {
    padding: 10px 0;

    img {
      object-fit: cover;
    }
  }

  .MuiGrid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 7px;
    width: 680px;
    margin: 0 auto;

    @media screen and (max-width: 870px) {
      display: flex;
      grid-template-columns: inherit;
      grid-gap: inherit;
      width: 229px;
    }
  }

  .button {
    background: #ad611a;
    padding: 20px;
    color: #fff;
    grid-column: 2;
    text-align: center;
    margin-top: 30px;
    font-size: 21px;
    transition: all ease 0.5s;

    &:hover {
      background: #b77231;
    }
  }
`;
