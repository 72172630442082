import styled from 'styled-components';
import 'react-bnb-gallery/dist/style.css';

export const Container = styled.div`
  height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1220px) {
    min-height: 130%;
  }

  ::after {
    content: '';
    background: #000;
    height: 110%;
    position: absolute;
    width: 100%;
    opacity: 0.4;
    @media screen and (max-width: 1220px) {
      height: 130%;
    }
  }

  .holder {
    z-index: 900;
    @media screen and (max-width: 1220px) {
      margin-top: -200px;
    }
    .content-center {
      p {
        color: #fff;
        font-size: 90px;
        text-align: center;
        font-family: 'Abril Fatface', cursive;
        @media screen and (max-width: 1520px) {
          font-size: 90px;
        }
        @media screen and (max-width: 870px) {
          font-size: 49px;
          padding-bottom: 17px;
        }

        &:first-of-type {
          &::after {
            content: '';
            position: absolute;
            height: 45px;
            width: 640px;
            z-index: -1;
            transform: translate(-75%, 119%);
            background: #feba45;

            @media screen and (max-width: 1520px) {
              width: 640px;
              height: 45px;
            }
            @media screen and (max-width: 870px) {
              height: 26px;
              width: 220px;
              transform: translate(-92%, 119%);
            }
          }
        }

        &:last-of-type {
          font-size: 86px;
          margin-top: -50px;
          font-family: 'Nickainley';
          @media screen and (max-width: 1520px) {
            font-size: 86px;
          }
          @media screen and (max-width: 870px) {
            font-size: 52px;
          }
        }
      }
    }

    .content-bottom {
      position: absolute;
      bottom: 106px;
      width: inherit;
      left: 50%;
      transform: translate(-50%, 100%);
      text-align: center;

      @media screen and (max-width: 1520px) {
        bottom: 75px;
      }

      p {
        font-size: 26px;
        color: #fff;
        font-family: 'Nickainley';
        @media screen and (max-width: 1520px) {
          font-size: 23px;
        }
        @media screen and (max-width: 870px) {
          font-size: 20px;
        }
      }
      img {
        cursor: pointer;
        margin-top: 22px;
        transition: all ease 0.9s;
        @media screen and (max-width: 1520px) {
          margin-top: 12px;
          width: 25px;
        }

        &:hover {
          transform: translateY(10px);
        }
      }
    }
  }
`;

export const Gallery = styled.div`
  height: auto;
  position: relative;

  background: var(--white-2);
  margin-top: 10rem;

  @media (max-width: 680px) {
    margin-top: 3rem;
  }

  .container {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    align-items: center;
    padding: 3rem 3rem;
    margin: var(--margin);
    /* max-width: 110rem; */
    position: relative;
    height: auto;
    z-index: 2;

    @media (max-width: 680px) {
      padding: 3rem 2rem;
    }

    .title {
      width: 100%;
      h1 {
        text-align: center;
        font-size: 60px;
      }
    }

    .grid {
      margin-top: 5rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      width: 100%;
      justify-items: center;
      grid-column-gap: 2rem;

      @media (max-width: 680px) {
        justify-items: stretch;
        grid-template-columns: 1fr;
      }

      .grid__block {
        margin: 0 0 2rem;
        height: auto;
        transition: all 430ms ease;

        .image {
          width: 100%;
          cursor: pointer;
          overflow: hidden;

          img {
            width: 100%;
            transition: all ease 0.3s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
`;

export const NossaHistoria = styled.section`
  height: auto;
  margin-bottom: 150px;
  position: relative;
  @media screen and (max-width: 870px) {
    margin-bottom: 20px;
  }

  .max-holder {
    overflow-x: hidden;
    margin-top: -59px;

    .block-yellow {
      height: 340px;
      background: #feba45;
      position: absolute;
      width: 1924px;
      top: 62px;
    }
    .content {
      position: relative;
      z-index: 10;
      display: grid;
      grid-template-columns: 50% 50%;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
      }

      img {
        width: 900px;
        max-width: 100%;
        margin-top: 180px;
        height: 79%;
        object-fit: cover;
        @media screen and (max-width: 1220px) {
          height: inherit;
          color: #000;
          margin: 100px 0 0 100px;
        }
        @media screen and (max-width: 870px) {
          margin: inherit;
          margin-top: 106px;
          object-fit: inherit;
          width: inherit;
        }
      }

      .texts {
        h1 {
          margin: 180px 0 0 100px;
          color: #fff;
          font-size: 70px;
          line-height: 80px;
          @media screen and (max-width: 1220px) {
            color: #3d3316;
            margin: 100px 0 0 100px;
          }
          @media screen and (max-width: 870px) {
            margin: 100px 0 0 30px;
            font-size: 60px;
            line-height: 55px;
          }
        }
        p {
          color: #848484;
          font-size: 15px;
          width: 431px;
          margin: 110px 0 0 130px;
          line-height: 25px;
          @media screen and (max-width: 1220px) {
            margin: 100px 0 0 100px;
          }
          @media screen and (max-width: 870px) {
            margin: inherit !important;
            padding: 46px;
            width: inherit;
          }
        }
        p:nth-child(3) {
          margin: 30px 0 30px 130px;
          padding-bottom: 30px;
          border-bottom: 7px solid #feba45;
          @media screen and (max-width: 1220px) {
            margin: 30px 0 0 100px;
          }
          @media screen and (max-width: 870px) {
            border-bottom: none;
            padding-top: 3px;
          }
        }
      }
    }

    .content-2 {
      display: grid;
      grid-template-columns: 0.5fr 1fr;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
      }

      .text-left {
        justify-content: flex-end;
        grid-column: 2/1;
        margin-top: 100px;
        display: flex;

        @media screen and (max-width: 1220px) {
          justify-content: center;
        }

        @media screen and (max-width: 870px) {
          justify-content: inherit;
          margin-top: 45px;
        }
        h1 {
          padding: 0 70px 0 165px;
          font-size: 70px;
          line-height: 65px;
          @media screen and (max-width: 1220px) {
            padding: inherit;
          }

          @media screen and (max-width: 870px) {
            font-size: 60px;
            line-height: 50px;
            margin-left: 30px;
          }
        }
      }
      .text-right {
        display: flex;
        justify-content: left;
        @media screen and (max-width: 870px) {
          display: inherit;
          padding: 46px;
        }
        @media screen and (max-width: 1220px) {
          justify-content: center;
        }
        p {
          color: #848484;
          font-size: 15px;
          width: 380px;
          margin: 148px 20px 0 10px;
          line-height: 25px;
          padding-bottom: 30px;
          padding-right: 40px;
          border-bottom: 7px solid #feba45;
          @media screen and (max-width: 1220px) {
            margin: 100px 20px 0 10px;
          }
          @media screen and (max-width: 870px) {
            width: inherit;
            margin: 8px 20px 0 10px;
            padding: 27px 0 30px 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .photos {
        background: black;
        height: 30rem;
        width: 100%;
        margin-top: 3rem;
        grid-column: 1/3;
      }
    }
    .content-3 {
      .container {
        max-width: 126rem;
        padding: 0 3rem;
        @media (max-width: 680px) {
          padding: 0 2rem;
        }
        .video {
          display: flex;
          justify-content: center;

          iframe {
            width: 100%;
            height: 100vh;
            max-height: 80rem;

            @media (max-width: 680px) {
              height: 50vh;
            }
          }
        }
      }
    }

    .cheese-background {
      overflow-y: hidden;

      img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
      p {
        color: #fff;
        font-size: 70px;
      }
    }
  }
`;

export const NossaExcelenciaArtesanal = styled.section`
  height: 1800px;
  position: relative;
  @media screen and (max-width: 870px) {
    height: initial;
  }
  .helper {
    background: #feba45;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 100px;
    z-index: 1;

    @media screen and (max-width: 870px) {
      position: initial;
      height: initial;
      width: initial;
      top: inherit;
    }
  }
  .grid1-content {
    display: grid;
    grid-template-columns: 50% 10%;
    @media screen and (max-width: 870px) {
      grid-template-columns: 30% 10%;
    }
    h1 {
      font-size: 65px;
      margin: 500px 0 0 170px;
      line-height: 57px;
      width: max-content;

      @media screen and (max-width: 1220px) {
        margin: 500px 0 0 67px;
      }

      @media screen and (max-width: 870px) {
        font-size: 60px;
        line-height: 57px;
        margin: 214px 0 0 30px;
        width: inherit;
      }
    }
    .image-right {
      overflow: hidden;
      grid-column: 3;
      img {
        width: 700px;
        margin: 200px 0 0 216px;
        @media screen and (max-width: 870px) {
          margin: 53px 0 0 0;
        }
      }
    }
  }

  .grid2-content {
    margin-top: 210px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 0.9fr 1fr;
    gap: 5rem;
    @media screen and (max-width: 870px) {
      margin-top: 84px;
      display: inherit;
      justify-content: inherit;
      grid-template-columns: inherit;
    }
    @media screen and (max-width: 1220px) {
      grid-template-columns: 50% 50%;
    }

    .blocks {
      padding: 0 15px 0 160px;

      @media screen and (max-width: 1220px) {
        padding: 0 15px 0 64px;
      }

      @media screen and (max-width: 870px) {
        padding: 0 15px 0 10px;
      }

      h1 {
        font-size: 35px;
        margin-bottom: 15px;
        @media screen and (max-width: 870px) {
          padding: 0 30px;
        }
      }
      p {
        padding: 0 10px 10px 0;
        margin-bottom: 20px;
        line-height: 25px;
        @media screen and (max-width: 870px) {
          padding: 0 10px 10px 30px;
        }
      }

      .block3 {
        position: relative;
        ::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 7px;
          background: #3d3316;
          margin-top: 50px;
          /* bottom: 10px; */
          @media screen and (max-width: 870px) {
            display: none;
          }
        }
      }
    }
  }
  .image-right {
    align-self: center;

    @media screen and (max-width: 870px) {
      align-self: inherit;
    }
    img {
      max-width: 100%;
    }
  }

  .image-right-queijos {
    align-self: center;
    img {
      max-width: 100%;
      margin-top: -100px;
      height: 870px;
      object-fit: cover;

      @media screen and (max-width: 870px) {
        margin-top: inherit;
        height: inherit;
        object-fit: inherit;
      }
    }
  }

  .last-image {
    display: flex;
    position: relative;
    justify-content: center;
    img {
      width: 1020px;
      max-width: 100%;
      z-index: 10;
      height: 100%;
      /* position: absolute;
      top: -120px; */
    }
  }
`;

export const NossosProdutos = styled.section`
  min-height: 3500px;
  position: relative;
  margin-bottom: 300px;
  z-index: 99;
  @media screen and (max-width: 870px) {
    min-height: 3800px !important;
  }

  /* @media screen and (max-width: 1520px) {
    height: 3900px;
  }
  @media screen and (max-width: 1220px) {
    height: 3400px;
  } */
  .max-holder {
    position: relative;
    height: 100%;
    width: 88%;
    background: #281627;

    @media screen and (max-width: 870px) {
      width: initial;
    }

    .image-bottom {
      position: absolute;
      z-index: 0;
      bottom: -200px;
      right: 50px;
    }

    h1 {
      padding: 100px 0 100px 100px;
      color: #fff;
      line-height: 65px;
      font-size: 70px;

      @media screen and (max-width: 870px) {
        font-size: 47px;
        line-height: 51px;
        padding: 58px 0 15px 26px;
      }
    }
    .banner-top {
      position: relative;
      max-width: fit-content;
      margin: auto;
      padding: 10px 74px 10px 77px;

      @media screen and (max-width: 870px) {
        padding: 10px 26px 10px 26px;
      }
      img {
        max-width: 100%;
        width: auto;
        height: 100%;
        object-fit: cover;

        @media screen and (max-width: 870px) {
          display: none;
        }
      }
      .text-center {
        max-width: fit-content;
        position: absolute;
        left: 0;
        top: 40%;
        @media screen and (max-width: 870px) {
          position: initial;
          top: inherit;
          max-width: initial;
        }
        p {
          padding: 0 250px;
          color: #fff;

          @media screen and (max-width: 870px) {
            margin: inherit;
            padding: inherit;
          }
        }
        ::after {
          content: '';
          position: absolute;
          width: 50%;
          height: 5px;
          background: #fff;
          top: 140%;
          left: 24%;
          right: auto;
          @media screen and (max-width: 870px) {
            width: 100px;
            top: 110%;
          }
        }
      }
    }

    .grid-content {
      position: relative;
      display: grid;
      grid-template-columns: 41% 41%;
      grid-gap: 350px 30px;
      justify-content: center;
      margin-top: 100px;
      padding: 0 3rem;

      @media screen and (max-width: 1820px) {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr)) !important;
      }

      @media screen and (max-width: 870px) {
        grid-template-columns: 100% !important;
        padding: 0 5%;
        grid-gap: 70px !important;
      }
      /* @media screen and (max-width: 1520px) {
        grid-template-columns: 41% 41%;
        grid-gap: 350px 10px;
      }

      @media screen and (max-width: 1420px) {
        grid-template-columns: 44% 42%;
        grid-gap: 350px 10px;
      }

      @media screen and (max-width: 1320px) {
        grid-template-columns: 47% 47%;
        grid-gap: 350px 20px;
      }

      @media screen and (max-width: 1220px) {
        grid-template-columns: 41% 41%;
        grid-gap: 350px 20px;
      } */

      #final-adjust {
        max-width: 100%;
        width: 650px;
        height: 100px;
        position: relative;

        @media screen and (max-width: 870px) {
          width: 100%;
          height: initial;
        }
      }

      .block {
        max-width: 100%;
        width: 100%;
        height: 600px;
        position: relative;
        z-index: 10;

        /* @media screen and (max-width: 1520px) {
          width: 530px !important;
          height: 450px !important;
        }
        @media screen and (max-width: 1420px) {
          width: 500px !important;
          height: 450px !important;
        }

        @media screen and (max-width: 1220px) {
          width: 380px !important;
          height: 350px !important;
        }

        @media screen and (max-width: 1020px) {
          width: 100% !important;
          height: 350px !important;
        } */

        @media screen and (max-width: 870px) {
          width: 100% !important;
          height: auto !important;
        }

        h1 {
          z-index: 5;
          position: absolute;
          bottom: 0;
          left: -36px;
          font-size: 50px;

          /* @media screen and (max-width: 1520px) {
            font-size: 40px;
            top: 234px;
            left: -42px;
          }
          @media screen and (max-width: 1220px) {
            font-size: 27px;
            top: 163px;
            left: -54px;
          } */

          @media screen and (max-width: 870px) {
            left: 0;
            bottom: 50%;
            transform: translate(0, -50%);
            font-size: calc(2rem + 1vw);
          }
        }

        img {
          position: relative;
          z-index: 4;
          width: inherit;
          height: inherit;
          object-fit: cover;
          transition: all ease 0.8s;
        }

        .block-bottom {
          z-index: 1;
          background: #feba45;
          width: 79%;
          margin: 0 auto;

          @media screen and (max-width: 1520px) {
            width: 89%;
          }

          @media screen and (max-width: 820px) {
            width: 100%;
          }

          p {
            color: #281627;
            position: relative;
            padding: 40px 40px 57px 40px;
            line-height: 25px;
            font-size: 14px;

            @media screen and (max-width: 870px) {
              padding: 39px 40px;
            }

            ::after {
              content: '';
              position: absolute;
              width: 90%;
              height: 7px;
              background: #281627;
              top: 78%;
              left: 5%;
              right: auto;
              @media screen and (max-width: 1520px) {
                top: 88%;
              }
              @media screen and (max-width: 870px) {
                display: none;
              }
            }
          }
        }
        :after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          z-index: 4;
          opacity: 0;
          background: rgba(0, 0, 0, 0.6);
          transition: all 0.5s ease 0s;
          overflow: hidden;
        }

        :hover:after {
          opacity: 0.6;
        }
      }
    }
  }
`;

export const Visite = styled.section`
  height: fit-content;
  position: relative;

  p {
    position: absolute;
    top: 110px;
    left: 110px;
    line-height: 65px;
    z-index: 50;
    font-family: 'Abril Fatface', cursive;
    color: #fff;
    font-size: 69px;

    @media screen and (max-width: 1020px) {
      top: 139px;
      left: 110px;
      line-height: 37px;
      font-size: 35px;
    }

    @media screen and (max-width: 870px) {
      font-size: 21px;
      top: 37px;
      left: 15px;
      line-height: 22px;
    }
    &:nth-child(2) {
      top: 170px;
      @media screen and (max-width: 870px) {
        top: 56px;
      }

      &::after {
        content: '';
        position: absolute;
        height: 68px;
        width: 545px;
        top: 18px;
        left: 541px;
        z-index: -1;
        transform: translate(-108%, 19%);
        background: #feba45;

        @media screen and (max-width: 1020px) {
          height: 37px;
          width: 250px;
          top: 11px;
          left: 259px;
        }

        @media screen and (max-width: 870px) {
          height: 19px;
          width: 158px;
          transform: translate(-167%, 17%);
        }
      }
    }
  }

  .slick-dots {
    bottom: 33px;
    z-index: 20;
  }

  .slick-dots li {
    margin-right: 30px;

    @media screen and (max-width: 870px) {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  .slick-dots li button:before {
    font-size: 22px;

    @media screen and (max-width: 870px) {
      font-size: 14px;
    }
  }

  .slick-dots li.slick-active button:before {
    color: #feba45;
  }
`;

export const Localizacao = styled.section`
  height: 1080px;
  overflow: hidden;

  @media screen and (max-width: 870px) {
    height: auto;
  }
  .agendar {
    font-size: 1.4rem;
  }

  p {
    text-align: center;
    margin-top: 50px;
    color: #281627;
    @media screen and (max-width: 870px) {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .horarios {
    p {
      font-size: 1.4rem;

      span {
        font-weight: bold;
      }
    }
  }

  .texts {
    width: 900px;
    margin: 0 auto 100px auto;
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 870px) {
      display: inherit;
      width: inherit;
      margin: 0 auto 80px auto;
    }

    p {
      font-family: 'Abril Fatface', cursive;
      color: #281627;

      &:first-of-type {
        font-size: 50px;
        @media screen and (max-width: 870px) {
          font-size: 39px;
        }
      }
      &:last-of-type {
        margin-top: 62px;
        font-size: 30px;

        span {
          padding-bottom: 5px;
          border-bottom: 5px solid #281627;
        }
      }

      span {
        font-size: 25px;
        margin-right: 10px;

        @media screen and (max-width: 870px) {
          font-size: 17px;
        }
      }
    }
  }

  .map-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 870px) {
      display: inherit;
    }
    .left-text {
      padding: 0 50px 0 150px;

      @media screen and (max-width: 870px) {
        padding: 0 0 50px 0;
        text-align: center;
      }
      h1 {
        font-size: 120px;
        line-height: 110px;
        margin-bottom: 0;
        color: #281627;
        @media screen and (max-width: 870px) {
          padding: 0 30px;
          font-size: 60px;
          line-height: 50px;
        }
      }

      p {
        font-size: 20px;

        @media screen and (max-width: 870px) {
          width: initial;
        }
      }
    }

    .map {
      iframe {
        max-width: 100%;
        @media screen and (max-width: 870px) {
          width: 100%;
        }
      }
    }
  }
`;

export const Contato = styled.section`
  height: 1080px;
  margin-top: -30px;
  z-index: 0;

  .max-holder {
    background: #281627;
    height: 100%;

    @media screen and (max-width: 870px) {
      height: 1200px;
    }

    .grid-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-top: 250px;
      justify-items: center;

      @media screen and (max-width: 870px) {
        grid-template-columns: inherit;
        padding-top: 55px;
      }

      h1 {
        font-size: 80px;
        color: #fff;
        line-height: 73px;
        @media screen and (max-width: 1320px) {
          font-size: 60px;
          line-height: 60px;
        }
        @media screen and (max-width: 870px) {
          padding: 50px 30px;
        }
      }

      .form-content {
        width: 600px;
        margin: auto;
        padding-right: 50px;
        @media screen and (max-width: 870px) {
          width: inherit;
          padding: 0 30px;
        }

        form {
          margin-top: 30px;
          transition: all ease-in-out 4s;

          span {
            color: #fafafa;
          }
          label {
            color: #fff;
            font-weight: 700;
            letter-spacing: 1px;
            font-size: 21px;
          }

          input {
            width: 100%;
            height: 35px;
            background: transparent;
            border: none;
            outline: none;
            color: #fff;
            border-bottom: 1px solid #feba45;
            font-size: 18px;
            margin-bottom: 40px;

            ::placeholder {
              color: lightgrey;
              opacity: 0.7;
            }
          }

          textarea {
            width: 100%;
            height: 100px;
            padding: 30px;
            background: transparent;
            border: none;
            outline: none;
            color: #fff;
            border-bottom: 1px solid #feba45;
            font-size: 14px;
            margin-bottom: 10px;
            resize: none;
          }
          .btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;
            padding-bottom: 32px;

            .button-submit {
              font-family: 'Abril Fatface', cursive;
              font-size: 20px;
              letter-spacing: 2px;
              background: #feba45;
              width: 150px;
              padding: 0;
              color: #fff;
              border-color: transparent;
              font-weight: bold;
              height: 45px;
              cursor: pointer;
              margin-bottom: 0;
              margin-top: 10px;
              transition: all ease 1s;

              &:hover {
                background: #ffc258;
              }
            }
          }
        }
      }
    }
  }
`;

export const Footer = styled.section`
  /* display: flex; */
  height: 100px;
  position: relative;
  /* justify-content: flex-end; */

  .grid-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 178px;

    @media screen and (max-width: 870px) {
      grid-template-columns: inherit;
    }
    .queijo {
      img {
        position: absolute;
        left: 0;
        top: -65%;
        max-width: 100%;
        width: 530px;

        @media screen and (max-width: 870px) {
          position: inherit;
        }
      }
    }
    p {
      font-family: 'Abril Fatface', cursive;
      text-align: end;
      padding: 70px 40px 5px 40px;
      img {
        padding: 0 10px;
      }
      a {
        color: #000;
      }
      @media screen and (max-width: 870px) {
        text-align: center;
        padding: 40px 0 6px 0;
      }

      &:last-of-type {
        padding: 3px 22px;
        margin-top: 50px;
        font-size: 14px;

        @media screen and (max-width: 870px) {
          padding: 10px 4px;
        }
      }
    }
  }
`;
