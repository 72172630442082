import React, { useState, useEffect } from 'react';

import Header from '../../components/Header';
import Instagram from '../../components/Instagram';

import { FaWhatsapp } from 'react-icons/fa';

import {
  Container,
  NossaHistoria,
  Gastronomia,
  Chef,
  Visite,
  Localizacao,
  Contato,
  Footer,
  Gallery,
} from './styles';
import BannerImage from '../../assets/images/eventos-background.jpg';
import ReactBnbGallery from 'react-bnb-gallery';

import 'antd/es/notification/style/css';
import ademail from '../../services/ademail';
import ReactHtmlParse from 'react-html-parser';
import { Helmet } from 'react-helmet';
import api from '../../services/api';
import Slider from 'react-slick';

import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { notification } from 'antd';

function GalleryJSX() {
  const [content, setContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [images, setImages] = useState([
    {
      photo: '',
      number: 0,
    },
  ]);

  useEffect(() => {
    const getContent = async () => {
      const { data } = await api.get('pages?slug=eventos');
      setContent(data);
    };
    getContent();
  }, []);

  !!content && handleImages();

  function handleImages() {
    const images = content[0].acf.nossas_raizes.galeria.map((element) => ({
      photo: element.url,
      number: element.id,
    }));

    setImages(images);
    setContent(false);
  }

  return (
    <Gallery>
      <div className="container">
        <div className="title">
          <h1>Conheça o espaço</h1>
        </div>
        <div className="grid">
          <ReactBnbGallery
            show={isOpen}
            photos={images}
            activePhotoIndex={imgIndex}
            onClose={() => setIsOpen(false)}
          />
          <div className="yellow-strip"></div>
          {!!images &&
            images.map((obj, index) => (
              <div key={obj.number} className="grid__block">
                <div className="image">
                  <img
                    src={obj.photo}
                    onClick={() => {
                      setIsOpen(true);
                      setImgIndex(index);
                    }}
                    alt="Photos"
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Gallery>
  );
}

export default function Eventos() {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const getContent = async () => {
      const { data } = await api.get('pages?slug=eventos');
      setContent(data);
    };
    getContent();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 824,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                <p><strong>Nome</strong>: ${values.nome}</p>
                <p><strong>E-mail:</strong> ${values.email}</p>
                <p><strong>Telefone:</strong> ${values.assunto}</p>
                <p><strong>Mensagem:</strong> ${values.mensagem}</p>
              `;

      const mail = {
        to: 'hallo@melkland.com.br',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: 'Novo contato via site -  Melkland - Boutique de queijos',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });
  return (
    <>
      <Helmet>
        <title>Eventos | Melkland</title>
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + '/thumb.jpg'}
        />
      </Helmet>
      <Header />

      <Container
        id="banner"
        style={{
          backgroundImage: 'url(' + BannerImage + ')',
        }}
        // style={{ backgroundImage: 'url(' + background + ')' }}
      >
        <a
          href="https://api.whatsapp.com/send?phone=5542991474248"
          className="whatsapp"
          target="_blank"
        >
          <FaWhatsapp color="#281627" />
        </a>
        <div className="holder">
          <div className="content-center">
            <p>O seu evento</p>
            <p>perfeito está aqui.</p>
          </div>
          <div className="content-bottom">
            <a href="#historia">
              <p>Saiba mais</p>
            </a>
            <a href="#historia">
              <img src={require('../../assets/images/SETA.svg')} alt="seta" />
            </a>
          </div>
        </div>
      </Container>

      <NossaHistoria>
        <div id="historia" className="max-holder">
          <div className="cheese-background">
            <img
              src={require('../../assets/images/top_cheese_background.svg')}
              alt="queijo"
            />
          </div>
          <div className="block-yellow"></div>
          <div className="content">
            <img
              src={require('../../assets/images/familia.jpg')}
              alt="queijo"
            />
            <div className="texts">
              <h1>
                Nossa <br />
                história
              </h1>
              {!!content &&
                content.map((obj) => ReactHtmlParse(obj.acf.nossa_historia))}
            </div>
          </div>
          <div className="content-2">
            <div className="text-left">
              <h1>
                Do seu <br />
                jeito
              </h1>
            </div>
            <div className="text-right">
              {!!content &&
                content.map((obj) => (
                  <>
                    <div>
                      <h2>100 Lugares</h2>
                      <p key={obj.id}>
                        {obj.acf.nossas_raizes.texto_da_esquerda}
                      </p>
                    </div>
                    <div>
                      <h2>Gastronomia impecável</h2>
                      <p>{obj.acf.nossas_raizes.texto_da_direita}</p>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
      </NossaHistoria>

      <Gastronomia>
        <div className="gastronomia">
          <div className="top-images">
            <div className="top-image">
              <img
                src={require('../../assets/images/top-image1.jpg')}
                alt="Gastronomia"
              />
            </div>
            <div className="top-image">
              <img
                src={require('../../assets/images/top-image2.jpg')}
                alt="Gastronomia"
              />
            </div>
            <div className="top-image">
              <img
                src={require('../../assets/images/top-image3.jpg')}
                alt="Gastronomia"
              />
            </div>
          </div>
          <div className="title">
            <h1>
              Uma <br />
              gastronomia <br />
              inenarrável
            </h1>
          </div>
          <div className="content">
            {!!content && (
              <>
                {content[0].acf.gastronomia.map((g) => {
                  return (
                    <div className="content-card">
                      {ReactHtmlParse(g.conteudo)}
                    </div>
                  );
                })}
              </>
            )}

            <a
              href="https://api.whatsapp.com/send?phone=5542991474248"
              className="reserva"
              target="_blank"
            >
              Faça sua reserva
            </a>
          </div>

          <div className="images">
            <div className="image image1">
              <img
                src={require('../../assets/images/gastronomia1.jpg')}
                alt="Gastronomia"
              />
            </div>
            <div className="image image2">
              <img
                src={require('../../assets/images/gastronomia2.jpg')}
                alt="Gastronomia"
              />
            </div>
            <div className="image image3">
              <img
                src={require('../../assets/images/gastronomia3.jpg')}
                alt="Gastronomia"
              />
            </div>
            <div className="image image4">
              <img
                src={require('../../assets/images/gastronomia4.jpg')}
                alt="Gastronomia"
              />
            </div>
          </div>

          <div className="bottom-detail">
            <img src={require('../../assets/images/cheese.svg')} alt="cheese" />
          </div>
        </div>
      </Gastronomia>

      <Chef>
        <div className="chef">
          <div className="content">
            <div className="s1">
              <img src={require('../../assets/images/chef.jpg')} alt="Chef" />
              <div className="text">
                <div className="yellow-text">
                  <div className="brown-square"></div>
                  <span>
                    + de 6 anos de <br />
                    experiência
                  </span>
                </div>
                <div className="yellow-text">
                  <div className="brown-square"></div>
                  <span>
                    Amor pela <br />
                    gastronomia
                  </span>
                </div>
              </div>
            </div>
            <div className="s2">
              <h1>
                Conheça <br /> o chef
              </h1>
              <h1 className="gui">gui</h1>
              <p>
                O Chef Gui Baran trabalha profissionalmente com gastronomia
                desde 1998, ano que se mudou para os EUA. No país, começou a
                trabalhar em restaurantes, onde exerceu todas as funções
                possíveis dentro de uma cozinha.
              </p>
              <p>
                Depois desse período, já no Brasil, frequentou o curso de Chef
                de Cuisine, do Centro Europeu. Em seguida ingressou no Espaço
                Gourmet, onde foi coordenador e abriu várias franquias pelo PR e
                SC.
              </p>
              <p>
                Anos depois, se aperfeiçoou em Paris, em cursos rápidos no
                Cordon Bleau, Ritz Escoffier e Allain Ducasse.
              </p>
              <p>
                Em 2014, passou a gerenciar a cozinha do Hard Rock Café
                Curitiba, com 44 cozinheiros.
              </p>
              <p>
                Depois da experiência como gerente de cozinha, realizou
                consultorias e inaugurou novos restaurantes. Os últimos foram a
                Tartuferia San Paolo, em Curitiba, e o Miro Cozinha & Vinho na
                cidade de Ponta Grossa.
              </p>
              <p>
                A convite da Melkland, em 2019 assumiu o restaurante anexo à
                queijaria e voltou a ministrar aulas, eventos, jantares e
                workshops.
              </p>
            </div>
          </div>
          <div className="chef-images">
            <div className="chef-images-s1">
              <div id="chef-image1" className="chef-image horizontal">
                <img
                  src={require('../../assets/images/chef1.jpg')}
                  alt="Hamburger"
                />
              </div>
              <div id="chef-image2" className="chef-image vertical">
                <img
                  src={require('../../assets/images/chef2.jpg')}
                  alt="Batata Frita"
                />
              </div>
            </div>
            <br />
            <div id="chef-image3" className="chef-image horizontal">
              <img
                src={require('../../assets/images/chef3.jpg')}
                alt="Petit Gâteau"
              />
            </div>
          </div>
        </div>
      </Chef>

      <GalleryJSX />
      <Visite>
        <div id="visitar" className="max-holder">
          <p>Visite e sinta a</p>
          <p>
            experiência <br /> Melkland
          </p>
          <Slider {...settings}>
            {!!content &&
              content.map((obj) =>
                obj.acf.fotos.map((photos) => (
                  <img key={Math.random()} src={photos.url} alt={photos.name} />
                ))
              )}
          </Slider>
        </div>
      </Visite>
      <Localizacao>
        <div className="max-holder">
          <p className="agendar">
            {/* Agende sua visita pelo telefone ou pelo e-mail: */}
          </p>

          <div className="horarios">
            <p>
              Horário de atendimento de segunda a sábado das <span>08:30</span>{' '}
              às <span>18 horas</span>
            </p>
          </div>
          <div className="texts">
            <p>
              <span>42</span>9 9147-4248
            </p>
            <p>
              hallo@<span>melkland.com.br</span>
            </p>
          </div>
          <div className="map-content">
            <div className="left-text">
              <h1>Onde ficamos</h1>
              <p>PR 340 km 219. Estrada de Castro para Castrolanda</p>
            </div>
            <div className="map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.708416279096!2d-49.96952768505801!3d-24.805436114589895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c29deefa27e3dd%3A0x4035cd400e070b24!2sMelkland!5e0!3m2!1spt-BR!2sbr!4v1599660047694!5m2!1spt-BR!2sbr"
                width="680"
                height="600"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </Localizacao>
      <div className="max-holder">
        <Instagram />
      </div>
      <Contato>
        <div id="contato" className="max-holder">
          <div className="grid-content">
            <div className="text-left">
              <h1>
                Contato <br />
                aqui!
              </h1>
            </div>
            <div className="form-content">
              <form id="contact-form" onSubmit={formik.handleSubmit}>
                <label htmlFor="nome" className="label">
                  Nome
                </label>
                <input
                  name="nome"
                  type="text"
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                />
                {formik.touched.nome && formik.errors.nome ? (
                  <span>{formik.errors.nome}</span>
                ) : null}
                <br />

                <label htmlFor="email" className="label">
                  E-mail
                </label>
                <input
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span>{formik.errors.email}</span>
                ) : null}
                <br />

                <label htmlFor="telefone" className="label">
                  Telefone
                </label>
                <InputMask
                  mask="(99)9 9999-9999"
                  name="telefone"
                  type="tel"
                  value={formik.values.telefone}
                  onChange={formik.handleChange}
                />
                {formik.touched.telefone && formik.errors.telefone ? (
                  <span>{formik.errors.telefone}</span>
                ) : null}
                <br />

                <label htmlFor="mensagem" className="label">
                  Mensagem
                </label>
                <textarea
                  name="mensagem"
                  type="text"
                  value={formik.values.mensagem}
                  onChange={formik.handleChange}
                />
                {formik.touched.mensagem && formik.errors.mensagem ? (
                  <span>{formik.errors.mensagem}</span>
                ) : null}
                <br />
                <div className="btn">
                  <input
                    className="button-submit"
                    name="button"
                    type="submit"
                    value="Enviar"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Contato>
      <Footer>
        <div className="max-holder">
          <div className="grid-footer">
            <div className="queijo">
              <img
                src={require('../../assets/images/cheese_contact.svg')}
                alt="queijo"
              />
            </div>
            <div className="text-right">
              <p>
                Todos os direitos reservados © Melkland. Desenvolvido com amor{' '}
                <img
                  src={require('../../assets/images/cheese_assinatura.svg')}
                  alt="queijo"
                />{' '}
                <a
                  href="https://agenciaade.com.br/"
                  target="__blank"
                  rel="noreferrer noopener"
                >
                  agenciaade.com.br
                </a>
              </p>
            </div>
          </div>
        </div>
      </Footer>
    </>
  );
}
