import React from 'react';
import { Container, InstagramHolder } from './styles';
import InstagramFeed from 'react-ig-feed';

import background from '../../assets/images/grade.png';

export default function Instagram() {
  return (
    <>
      <Container style={{ backgroundImage: 'url(' + background + ')' }}>
        <div className="top-text">
          <div className="text">
            <p>Siga a gente no instagram</p>
          </div>
        </div>
        <div className="image-bottom">
          <img
            src={require('../../assets/images/cheese_localizacao.svg')}
            alt="queijo"
          />
        </div>
        <InstagramHolder>
          <div className="insta__photos">
            <InstagramFeed
              token="IGQVJWQUZAmU2hSUE44N1Ezb0VXdVhNdFIyNzY0b1M0ektjVnFCZAkppWkF2UUZAzOWl2NkhIc29JamIycUdMdDlkUVpwZAUJxMThORC1WRlRuY3dFS05xY19LalN6MzVxejFUUVJlbHU3eHpKQ1dHbF9ZANwZDZD"
              counter="6"
            />
          </div>
        </InstagramHolder>
      </Container>
    </>
  );
}
