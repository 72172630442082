import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import ReactHtmlParse from 'react-html-parser';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Loader } from 'semantic-ui-react';
import { notification } from 'antd';
import ReactBnbGallery from 'react-bnb-gallery';

import 'antd/es/notification/style/css';
// Email
import ademail from '../../services/ademail';

import background from '../../assets/images/background.jpg';

import {
  Container,
  NossaHistoria,
  NossaExcelenciaArtesanal,
  NossosProdutos,
  Visite,
  Localizacao,
  Contato,
  Footer,
  Gallery,
} from './styles';

import Header from '../../components/Header';

import Instagram from '../../components/Instagram';
import api from '../../services/api';

function GalleryJSX() {
  const [content, setContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [images, setImages] = useState([
    {
      photo: '',
      number: 0,
    },
  ]);

  useEffect(() => {
    const getContent = async () => {
      const { data } = await api.get('pages?slug=home');
      setContent(data);
    };
    getContent();
  }, []);

  !!content && handleImages();

  function handleImages() {
    const images = content[0].acf.nossas_raizes.galeria.map((element) => ({
      photo: element.url,
      number: element.id,
    }));

    setImages(images);
    setContent(false);
  }

  return (
    <Gallery>
      <div className="container">
        <div className="title">
          <h1>Galeria</h1>
        </div>
        <div className="grid">
          <ReactBnbGallery
            show={isOpen}
            photos={images}
            activePhotoIndex={imgIndex}
            onClose={() => setIsOpen(false)}
          />
          {!!images &&
            images.map((obj, index) => (
              <div key={obj.number} className="grid__block">
                <div className="image">
                  <img
                    src={obj.photo}
                    onClick={() => {
                      setIsOpen(true);
                      setImgIndex(index);
                    }}
                    alt="Photos"
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Gallery>
  );
}

export default function Home() {
  const [content, setContent] = useState(null);

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
                <p><strong>Nome</strong>: ${values.nome}</p>
                <p><strong>E-mail:</strong> ${values.email}</p>
                <p><strong>Telefone:</strong> ${values.assunto}</p>
                <p><strong>Mensagem:</strong> ${values.mensagem}</p>
              `;

      const mail = {
        to: 'hallo@melkland.com.br',
        from: values.email,
        as: values.nome,
        bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: 'Novo contato via site -  Melkland - Boutique de queijos',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomRight',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomRight',
        });
      }
    },
  });

  useEffect(() => {
    const getContent = async () => {
      const { data } = await api.get('pages?slug=home');
      setContent(data);
    };
    getContent();
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 824,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (content === null) {
    return <Loader size="large" active inline="centered" />;
  } else {
    return (
      <>
        <Helmet>
          <title>Melkland - Boutique de queijos</title>
          <meta
            property="og:image"
            content={process.env.PUBLIC_URL + '/thumb.jpg'}
          />
        </Helmet>

        <Header />

        <Container
          id="banner"
          style={{ backgroundImage: 'url(' + background + ')' }}
        >
          <div className="holder">
            <div className="content-center">
              <p>O queijo</p>
              <p>que a gente ama</p>
            </div>
            <div className="content-bottom">
              <a href="#historia">
                <p>Conheça nossa história</p>
              </a>
              <a href="#historia">
                <img src={require('../../assets/images/SETA.svg')} alt="seta" />
              </a>
            </div>
          </div>
        </Container>
        <NossaHistoria>
          <div id="historia" className="max-holder">
            <div className="cheese-background">
              <img
                src={require('../../assets/images/top_cheese_background.svg')}
                alt="queijo"
              />
            </div>
            <div className="block-yellow"></div>
            <div className="content">
              <img
                src={require('../../assets/images/familia.jpg')}
                alt="queijo"
              />
              <div className="texts">
                <h1>
                  Nossa <br />
                  história
                </h1>
                {!!content &&
                  content.map((obj) => ReactHtmlParse(obj.acf.nossa_historia))}
              </div>
            </div>
            <div className="content-2">
              <div className="text-left">
                <h1>
                  Nossas <br />
                  raízes
                </h1>
              </div>
              <div className="text-right">
                {!!content &&
                  content.map((obj) => (
                    <>
                      <p key={obj.id}>
                        {obj.acf.nossas_raizes.texto_da_esquerda}
                      </p>
                      <p>{obj.acf.nossas_raizes.texto_da_direita}</p>
                    </>
                  ))}
              </div>
            </div>
            <div className="content-3">
              <GalleryJSX />
              <div className="container">
                <div className="video">
                  {!!content &&
                    ReactHtmlParse(content[0].acf.nossas_raizes.iframe)}
                </div>
              </div>
            </div>
          </div>
        </NossaHistoria>
        <NossaExcelenciaArtesanal>
          <div className="last-image">
            <img src={require('../../assets/images/vacas.jpg')} alt="vacas" />
          </div>
          <div className="helper">
            <div className="max-holder">
              <div className="grid1-content">
                <div>
                  <h1>
                    Nossa excelência <br />
                    artesanal
                  </h1>
                </div>
                <div className="image-right">
                  <img
                    src={require('../../assets/images/cheese.svg')}
                    alt="cheese"
                  />
                </div>
              </div>
              <div className="grid2-content">
                <div className="blocks">
                  <div className="block1">
                    <h1>Qualidade de fazenda</h1>
                    {!!content &&
                      content.map((obj) => (
                        <p key={obj.id}>
                          {obj.acf.nossa_excelencia.qualidade_de_fazenda}
                        </p>
                      ))}
                  </div>
                  <div className="block2">
                    <h1>Sabor inconfundível</h1>
                    {!!content &&
                      content.map((obj) => (
                        <p key={obj.id}>
                          {obj.acf.nossa_excelencia.sabor_inconfundivel}
                        </p>
                      ))}
                  </div>
                  <div className="block3">
                    <h1>Experiência de campo</h1>
                    {!!content &&
                      content.map((obj) => (
                        <p key={obj.id}>
                          {obj.acf.nossa_excelencia.experiencia_de_campo}
                        </p>
                      ))}
                  </div>
                </div>
                <div className="image-right-queijos">
                  <img
                    src={require('../../assets/images/many_cheeses.jpg')}
                    alt="many cheeses"
                  />
                </div>
              </div>
            </div>
          </div>
        </NossaExcelenciaArtesanal>
        <NossosProdutos>
          <div id="produtos" className="max-holder">
            <h1>
              Nossos <br />
              Produtos
            </h1>
            <div className="banner-top">
              <img
                src={require('../../assets/images/bread.jpg')}
                alt="many cheeses"
              />
              <div className="text-center">
                {!!content &&
                  content.map((obj) => <p key={obj.id}>{obj.acf.descricao}</p>)}
              </div>
            </div>
            <div className="grid-content">
              {!!content &&
                content.map((obj) =>
                  obj.acf.produtos.map((prods) => (
                    <div key={Math.random()} className="block">
                      <h1>{prods.titulo}</h1>
                      <img src={prods.imagem.url} alt="produto 1" />
                      <div className="block-bottom">
                        <p>{prods.descricao}</p>
                      </div>
                    </div>
                  ))
                )}
              <div id="final-adjust"></div>
              <div id="final-adjust"></div>
            </div>
            <div className="image-bottom">
              <img
                src={require('../../assets/images/queijo_desenho.svg')}
                alt="queijo"
              />
            </div>
          </div>
        </NossosProdutos>
        <Visite>
          <div id="visitar" className="max-holder">
            <p>Visite e sinta a</p>
            <p>
              experiência <br /> Melkland
            </p>
            <Slider {...settings}>
              {!!content &&
                content.map((obj) =>
                  obj.acf.fotos.map((photos) => (
                    <img
                      key={Math.random()}
                      src={photos.url}
                      alt={photos.name}
                    />
                  ))
                )}
            </Slider>
          </div>
        </Visite>
        <Localizacao>
          <div className="max-holder">
            <p className="agendar">
              {/* Agende sua visita pelo telefone ou pelo e-mail: */}
            </p>

            <div className="horarios">
              <p>
                Horário de atendimento de segunda a sábado das{' '}
                <span>08:30</span> às <span>18 horas</span>
              </p>
            </div>
            <div className="texts">
              <p>
                <span>42</span>9 9147-4248
              </p>
              <p>
                hallo@<span>melkland.com.br</span>
              </p>
            </div>
            <div className="map-content">
              <div className="left-text">
                <h1>Onde ficamos</h1>
                <p>PR 340 km 219. Estrada de Castro para Castrolanda</p>
              </div>
              <div className="map">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.708416279096!2d-49.96952768505801!3d-24.805436114589895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c29deefa27e3dd%3A0x4035cd400e070b24!2sMelkland!5e0!3m2!1spt-BR!2sbr!4v1599660047694!5m2!1spt-BR!2sbr"
                  width="680"
                  height="600"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </Localizacao>
        <div className="max-holder">
          <Instagram />
        </div>
        <Contato>
          <div id="contato" className="max-holder">
            <div className="grid-content">
              <div className="text-left">
                <h1>
                  Contato <br />
                  aqui!
                </h1>
              </div>
              <div className="form-content">
                <form id="contact-form" onSubmit={formik.handleSubmit}>
                  <label htmlFor="nome" className="label">
                    Nome
                  </label>
                  <input
                    name="nome"
                    type="text"
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <span>{formik.errors.nome}</span>
                  ) : null}
                  <br />

                  <label htmlFor="email" className="label">
                    E-mail
                  </label>
                  <input
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <span>{formik.errors.email}</span>
                  ) : null}
                  <br />

                  <label htmlFor="telefone" className="label">
                    Telefone
                  </label>
                  <InputMask
                    mask="(99)9 9999-9999"
                    name="telefone"
                    type="tel"
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.telefone && formik.errors.telefone ? (
                    <span>{formik.errors.telefone}</span>
                  ) : null}
                  <br />

                  <label htmlFor="mensagem" className="label">
                    Mensagem
                  </label>
                  <textarea
                    name="mensagem"
                    type="text"
                    value={formik.values.mensagem}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.mensagem && formik.errors.mensagem ? (
                    <span>{formik.errors.mensagem}</span>
                  ) : null}
                  <br />
                  <div className="btn">
                    <input
                      className="button-submit"
                      name="button"
                      type="submit"
                      value="Enviar"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Contato>
        <Footer>
          <div className="max-holder">
            <div className="grid-footer">
              <div className="queijo">
                <img
                  src={require('../../assets/images/cheese_contact.svg')}
                  alt="queijo"
                />
              </div>
              <div className="text-right">
                <p>
                  Todos os direitos reservados © Melkland. Desenvolvido com amor{' '}
                  <img
                    src={require('../../assets/images/cheese_assinatura.svg')}
                    alt="queijo"
                  />{' '}
                  <a
                    href="https://agenciaade.com.br/"
                    target="__blank"
                    rel="noreferrer noopener"
                  >
                    agenciaade.com.br
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Footer>
      </>
    );
  }
}
