import styled from 'styled-components';
import nav_background from '../../assets/images/bg_nav.png';

export const Container = styled.header`
  position: relative;
  height: 0;
  max-width: 86rem;
  margin: 0 auto;

  .holder.scrolled.active {
    top: 0;
  }

  .holder.scrolled {
    position: fixed;
    top: -100%;
  }

  .holder {
    white-space: nowrap;
    position: relative;
    left: 0;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 20px 0;
    justify-content: center;
    transition: all ease 0.4s;
    img {
      width: 230px;
      margin-right: 150px;
      transition: all ease 0.2s;

      @media screen and (max-width: 1520px) {
        width: 230px;
      }
      @media screen and (max-width: 1220px) {
        width: 170px;
        margin-left: 40px;
      }
      @media screen and (max-width: 920px) {
        /* padding-left: 100px; */
        width: 130px;
      }
      @media screen and (max-width: 870px) {
        width: 100px;
        position: absolute;
        margin-left: 50px;
        top: 6vw;
        left: 0;
      }
    }

    ul.is-active {
      display: block;
      position: absolute;
      top: 250%;
      left: 0;
      width: 100%;
      background: #e3a841;

      li {
        border-bottom: none;
        padding: 18px;

        ::after {
          left: 0;
        }
      }
    }

    ul {
      display: flex;
      @media screen and (max-width: 870px) {
        display: none;
      }

      li {
        cursor: pointer;
        color: #fff;
        z-index: 3;
        margin-right: 50px;
        font-size: 20px;
        position: relative;
        padding: 10px;
        /* border-bottom: 5px solid #feba45; */
        transition: all ease 0.3s;
        @media screen and (max-width: 920px) {
          font-size: 14px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 5px;
          width: 80%;
          background: #feba45;
          left: 10%;

          z-index: -1;
          transition: all ease-out 0.3s;
        }

        &:hover {
          &::after {
            width: 100%;
            height: 100%;
            left: 0;
          }
          color: #6c2d0c;
        }
      }

      li.scrolled {
        &:hover {
          color: #281627;
        }
      }
    }
  }

  .holder.scrolled {
    background: url(${nav_background});
    background-size: cover;
    height: 90px;
    width: 100%;
    background-repeat: no-repeat;
    padding-top: 55px;

    .hamburger {
      top: 27px;
    }
    img {
      width: 83px;

      /* @media screen and (max-width: 1320px) {
        width: 150px;
        padding-left: 50px;
      } */
      @media screen and (max-width: 870px) {
        top: 32px;
      }
    }

    ul.is-active {
      top: 99%;
      li {
        border-bottom: none;
        margin-bottom: 10px;
      }
    }

    ul {
      a.active {
        li {
          color: #281627;
          &::after {
            width: 100%;
          }
        }
      }
      li {
        margin-top: -8px;
        font-size: 16px;
      }
    }
  }

  .hamburger {
    padding: 15px 15px;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @media screen and (max-width: 870px) {
      display: inline-block;
      position: absolute;
      right: 10vw;
      top: 5vw;
    }
  }
  .hamburger:hover {
    opacity: 0.7;
  }
  .hamburger.is-active:hover {
    opacity: 0.7;
  }
  .hamburger .hamburger-inner,
  .hamburger .hamburger-inner::before,
  .hamburger .hamburger-inner::after {
    background-color: #fff;
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }
  .hamburger-inner::before {
    top: -10px;
  }
  .hamburger-inner::after {
    bottom: -10px;
  }

  /*
   * Squeeze
   */
  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  .hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease,
      transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;
