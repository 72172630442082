import { createGlobalStyle } from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import nickainley from '../assets/fonts/Nickainley-Normal.otf';
import fatface from '../assets/fonts/AbrilFatface-Regular.ttf';
import 'semantic-ui-css/semantic.min.css';

export default createGlobalStyle`
  @font-face { font-family: Nickainley; src: url(${nickainley}); font-weight: normal;} 
  @font-face { font-family: FatFaceLight; src: url(${fatface});} 
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    scroll-behavior: smooth;
  }

  html,
  body,
  #root {
    height: 100%;
    --yellow: #feba45;
    --brown: #3d3316;
  }

  body {
    overflow-x: hidden;
    font-family: 'Abril Fatface', cursive;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  p{
    font-family:'PT Sans', sans-serif;
    margin-bottom: 0;
  }

  h1{
    color: #3D3316;
    font-family: 'FatFaceLight';
  }

  button, input, textarea {
    :focus {
      outline: none;
    }
  }

  button{
    border: none;
    outline: none;
  }

  .max-holder {
    max-width: 1970px;
    position: relative;
    margin: 0 auto;
  }

  .slick-arrow.slick-next {
    display: none !important;
  }
  .slick-arrow.slick-prev {
    display: none !important;
  }

  .ui.centered.inline.loader.active, .ui.centered.inline.loader.visible{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
}

.gallery-figcaption--info .caption-right{
    display: none;
  }

  .gallery-figcaption .gallery-thumbnails--toggle{
    display: none;
  }

`;
