import React, { useEffect, useState } from 'react';

import { Container } from './styles';

export default function Header() {
  const [seMenuAberto, setSeMenuAberto] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  function abreFechaMenu() {
    setSeMenuAberto(!seMenuAberto);
  }

  useEffect(() => {
    window.onscroll = function () {
      const top = window.pageYOffset;

      var obj = document.getElementById('banner').scrollHeight;

      if (top > obj - 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // if (window.pageYOffset >= sticky) {
    //   obj.classList.add('sticky');
    // } else {
    //   obj.classList.remove('sticky');
    // }

    // window.addEventListener('scroll', () => {
    //   const isTop = window.scrollY < 650;
    //   if (isTop !== true) {
    //     setScrolled(true);
    //   } else {
    //     setScrolled(false);
    //   }
    // });
  });
  return (
    <>
      <Container>
        <div className={`holder scrolled ${scrolled && 'active'}`}>
          <a href="/">
            <img
              src={
                !scrolled
                  ? require('../../assets/images/logo.svg')
                  : require('../../assets/images/logo_scrolled.svg')
              }
              alt="logo"
            />
          </a>
          <button
            className={`hamburger  hamburger--squeeze ${
              seMenuAberto && 'is-active'
            }`}
            type="button"
            onClick={abreFechaMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <ul className={!seMenuAberto ? null : 'is-active'}>
            <a
              activeClass="active"
              href="/#historia"
              spy={true}
              offset={0}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Nossa História</li>
            </a>
            <a
              activeClass="active"
              href="/#produtos"
              spy={true}
              offset={-100}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Produtos</li>
            </a>
            <a
              activeClass="active"
              href="/#visitar"
              spy={true}
              offset={-100}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Visitas</li>
            </a>
            {/* <a href="/eventos">
              <li>Eventos</li>
            </a> */}
            <a
              activeClass="active"
              href="/#contato"
              spy={true}
              offset={-100}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Contatos</li>
            </a>
          </ul>
        </div>
        <div className={`holder`}>
          <a href="#">
            <img
              src={
                !scrolled
                  ? require('../../assets/images/logo.svg')
                  : require('../../assets/images/logo_scrolled.svg')
              }
              alt="logo"
            />
          </a>
          <button
            className={`hamburger  hamburger--squeeze ${
              seMenuAberto && 'is-active'
            }`}
            type="button"
            onClick={abreFechaMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <ul className={!seMenuAberto ? null : 'is-active'}>
            <a
              activeClass="active"
              href="/#historia"
              spy={true}
              offset={0}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Nossa História</li>
            </a>
            <a
              activeClass="active"
              href="/#produtos"
              spy={true}
              offset={-100}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Produtos</li>
            </a>
            <a
              activeClass="active"
              href="/#visitar"
              spy={true}
              offset={-100}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Visitas</li>
            </a>
            {/* <a href="/eventos">
              <li>Eventos</li>
            </a> */}
            <a
              activeClass="active"
              href="/#contato"
              spy={true}
              offset={-100}
              duration={2000}
            >
              <li className={!scrolled ? null : 'scrolled'}>Contatos</li>
            </a>
          </ul>
        </div>
      </Container>
    </>
  );
}
