import styled from 'styled-components';
import 'react-bnb-gallery/dist/style.css';

export const Container = styled.div`
  height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1220px) {
    min-height: 130%;
  }

  ::after {
    content: '';
    background: #000;
    height: 110%;
    position: absolute;
    width: 100%;
    opacity: 0.4;
    @media screen and (max-width: 1220px) {
      height: 130%;
    }
  }

  .whatsapp {
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 50%;
    outline: 10px solid #281627;
    height: 60px;
    width: 60px;
    background: #feba45;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    svg {
      height: 40px;
      width: 40px;
    }
  }

  .holder {
    z-index: 900;
    @media screen and (max-width: 1220px) {
      margin-top: -200px;
    }
    .content-center {
      p {
        color: #fff;
        font-size: 90px;
        text-align: center;
        font-family: 'Abril Fatface', cursive;
        @media screen and (max-width: 1520px) {
          font-size: 90px;
        }
        @media screen and (max-width: 870px) {
          font-size: 49px;
          padding-bottom: 17px;
        }

        &:first-of-type {
          &::after {
            content: '';
            position: absolute;
            height: 45px;
            width: 640px;
            z-index: -1;
            transform: translate(-90%, 119%);
            background: #feba45;

            @media screen and (max-width: 1520px) {
              width: 640px;
              height: 45px;
            }
            @media screen and (max-width: 870px) {
              height: 26px;
              width: 220px;
              transform: translate(-92%, 119%);
            }
          }
        }

        &:last-of-type {
          font-size: 86px;
          margin-top: -50px;
          font-family: 'Nickainley';
          @media screen and (max-width: 1520px) {
            font-size: 86px;
          }
          @media screen and (max-width: 870px) {
            font-size: 52px;
          }
        }
      }
    }

    .content-bottom {
      position: absolute;
      bottom: 106px;
      width: inherit;
      left: 50%;
      transform: translate(-50%, 100%);
      text-align: center;

      @media screen and (max-width: 1520px) {
        bottom: 75px;
      }

      p {
        font-size: 26px;
        color: #fff;
        font-family: 'Nickainley';
        @media screen and (max-width: 1520px) {
          font-size: 23px;
        }
        @media screen and (max-width: 870px) {
          font-size: 20px;
        }
      }
      img {
        cursor: pointer;
        margin-top: 22px;
        transition: all ease 0.9s;
        @media screen and (max-width: 1520px) {
          margin-top: 12px;
          width: 25px;
        }

        &:hover {
          transform: translateY(10px);
        }
      }
    }
  }
`;

export const Gallery = styled.div`
  height: auto;
  position: relative;

  background: var(--white-2);
  /* margin-top: 10rem; */
  margin-top: 600px;
  @media (max-width: 800px) {
    margin-top: 800px !important;
  }

  @media (max-width: 680px) {
    margin-top: 3rem;
  }

  .container {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    align-items: center;
    padding: 3rem 3rem;
    margin: var(--margin);
    /* max-width: 110rem; */
    position: relative;
    height: auto;
    z-index: 2;

    @media (max-width: 680px) {
      padding: 3rem 2rem;
    }

    .title {
      width: 100%;
      h1 {
        text-align: center;
        font-size: 60px;
      }
    }

    .grid {
      margin-top: 5rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      width: 100%;
      justify-items: center;
      grid-column-gap: 2rem;
      position: relative;

      .yellow-strip {
        position: absolute;
        width: 100vw;
        height: 200px;
        background: var(--yellow);
        top: 0;
        bottom: 0;
        margin: auto 0;
        z-index: -1;
      }

      @media (max-width: 680px) {
        justify-items: stretch;
        grid-template-columns: 1fr;
      }

      .grid__block {
        margin: 0 0 2rem;
        height: auto;
        transition: all 430ms ease;

        .image {
          width: 100%;
          cursor: pointer;
          overflow: hidden;

          img {
            width: 100%;
            transition: all ease 0.3s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
`;

export const NossaHistoria = styled.section`
  height: auto;
  margin-bottom: 150px;
  position: relative;
  @media screen and (max-width: 870px) {
    margin-bottom: 20px;
  }

  .max-holder {
    overflow-x: hidden;
    margin-top: -59px;

    .block-yellow {
      height: 340px;
      background: #feba45;
      position: absolute;
      width: 1924px;
      top: 62px;
    }
    .content {
      position: relative;
      z-index: 10;
      display: grid;
      grid-template-columns: 50% 50%;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
      }

      img {
        width: 900px;
        max-width: 100%;
        margin-top: 180px;
        height: 79%;
        object-fit: cover;
        @media screen and (max-width: 1220px) {
          height: inherit;
          color: #000;
          margin: 100px 0 0 100px;
        }
        @media screen and (max-width: 870px) {
          margin: inherit;
          margin-top: 106px;
          object-fit: inherit;
          width: inherit;
        }
      }

      .texts {
        h1 {
          margin: 180px 0 0 100px;
          color: #fff;
          font-size: 70px;
          line-height: 80px;
          @media screen and (max-width: 1220px) {
            color: #3d3316;
            margin: 100px 0 0 100px;
          }
          @media screen and (max-width: 870px) {
            margin: 100px 0 0 30px;
            font-size: 60px;
            line-height: 55px;
          }
        }
        p {
          color: #848484;
          font-size: 15px;
          width: 431px;
          margin: 110px 0 0 130px;
          line-height: 25px;
          @media screen and (max-width: 1220px) {
            margin: 100px 0 0 100px;
          }
          @media screen and (max-width: 870px) {
            margin: inherit !important;
            padding: 46px;
            width: inherit;
          }
        }
        p:nth-child(3) {
          margin: 30px 0 30px 130px;
          padding-bottom: 30px;
          border-bottom: 7px solid #feba45;
          @media screen and (max-width: 1220px) {
            margin: 30px 0 0 100px;
          }
          @media screen and (max-width: 870px) {
            border-bottom: none;
            padding-top: 3px;
          }
        }
      }
    }

    .content-2 {
      display: grid;
      grid-template-columns: 0.5fr 1fr;

      @media screen and (max-width: 1220px) {
        grid-template-columns: 1fr;
      }

      .text-left {
        justify-content: flex-end;
        grid-column: 2/1;
        margin-top: 100px;
        display: flex;

        @media screen and (max-width: 1220px) {
          justify-content: center;
        }

        @media screen and (max-width: 870px) {
          justify-content: inherit;
          margin-top: 45px;
        }
        h1 {
          padding: 0 70px 0 165px;
          font-size: 70px;
          line-height: 65px;
          @media screen and (max-width: 1220px) {
            padding: inherit;
          }

          @media screen and (max-width: 870px) {
            font-size: 60px;
            line-height: 50px;
            margin-left: 30px;
          }
        }
      }
      .text-right {
        display: flex;
        justify-content: left;
        div {
          margin: 148px 20px 0 10px;
          h2 {
            font-family: 'FatFaceLight';
            font-size: 28px;
          }
          @media screen and (max-width: 1220px) {
            margin: 100px 20px 0 10px;
          }
          @media screen and (max-width: 870px) {
            width: inherit;
            margin: 8px 20px 0 10px;
            padding: 27px 0 30px 0;
          }
        }
        @media screen and (max-width: 870px) {
          display: inherit;
          padding: 46px;
        }
        @media screen and (max-width: 1220px) {
          justify-content: center;
        }
        p {
          color: #848484;
          font-size: 15px;
          width: 380px;
          line-height: 25px;
          padding-bottom: 30px;
          padding-right: 40px;
          border-bottom: 7px solid #feba45;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .photos {
        background: black;
        height: 30rem;
        width: 100%;
        margin-top: 3rem;
        grid-column: 1/3;
      }
    }
    .content-3 {
      .container {
        max-width: 126rem;
        padding: 0 3rem;
        @media (max-width: 680px) {
          padding: 0 2rem;
        }
        .video {
          display: flex;
          justify-content: center;

          iframe {
            width: 100%;
            height: 100vh;
            max-height: 80rem;

            @media (max-width: 680px) {
              height: 50vh;
            }
          }
        }
      }
    }

    .cheese-background {
      overflow-y: hidden;

      img {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
      }
      p {
        color: #fff;
        font-size: 70px;
      }
    }
  }
`;

export const Gastronomia = styled.div`
  width: 100%;
  background: #feba45;
  .gastronomia {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 300px;
    position: relative;
    margin: 0 auto;
    margin-top: 400px;
    max-width: 1500px;

    @media (max-width: 800px) {
      margin-top: 0;
      padding-top: 200px;
      .top-images {
        display: none !important;
      }
    }

    .top-images {
      position: absolute;
      top: -300px;
      display: flex;
      .top-image {
        clip-path: inset(0 100px 0 0);
        margin-right: -100px;
        transition: 0.3s ease;
        img {
          width: 350px;
          height: 500px;
          object-fit: cover;
        }
        &:hover {
          clip-path: inset(0 0 0 0);
          margin-right: 0;
        }
      }
    }

    .images {
      .image {
        position: absolute;
        top: 40%;

        img {
          width: 350px;
          height: 500px;
          object-fit: cover;
        }
      }
      .image1 {
        left: 0;
      }
      .image2 {
        right: 0;
      }
      .image3 {
        left: 100px;
        top: 70%;
      }
      .image4 {
        right: 100px;
        top: 70%;
      }

      @media (max-width: 1300px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .image {
          position: static;
          img {
            width: 100%;
            height: 100%;
            padding: 20px;
          }
        }
      }
      @media (max-width: 550px) {
        grid-template-columns: 1fr;
      }
    }

    h1 {
      font-size: 70px;
      line-height: 57px;
      text-align: center;
      @media (max-width: 450px) {
        font-size: 45px;
      }
    }
    h2 {
      font-family: 'FatFaceLight';
      font-size: 28px;
    }
    p {
      font-size: 14px;
    }
    .content {
      width: 100%;
      padding: 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .content-card {
        width: 100%;
        max-width: 350px;
        padding-bottom: 20px;
        &:last-of-type {
          border-bottom: 7px solid #3d3316;
        }
      }
      .reserva {
        margin: 50px 0;
        padding: 10px 50px;
        background: black;
        border: 0;
        color: white;
        font-family: 'FatFaceLight';
        font-size: 22px;
      }
    }
    .bottom-detail {
      margin-bottom: -200px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      img {
        /* width: 100%; */
        height: 350px;
        clip-path: inset(0 0 200px 0);
      }
    }
  }
`;

export const Chef = styled.div`
  width: 100%;
  background: #281627;
  .chef {
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 200px;
    padding: 100px 20px;
    position: relative;
    .content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1000px) {
        flex-direction: column-reverse;
      }

      .s1 {
        width: 50%;
        padding: 0 20px;
        img {
          width: 100%;
          height: 100%;
          max-height: 600px;
          object-fit: cover;
        }

        .text {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .yellow-text {
            position: relative;
            span {
              font-size: 28px;
              color: var(--yellow);
              position: relative;
              z-index: 2;
            }
            .brown-square {
              position: absolute;
              top: -40px;
              left: -40px;
              width: 100px;
              height: 100px;
              background: var(--brown);
            }
          }
          @media (max-width: 560px) {
            .yellow-text {
              .brown-square {
                display: none;
              }
            }
          }
          @media (max-width: 500px) {
            flex-direction: column;
          }
        }
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
      .s2 {
        width: 50%;
        height: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        h1 {
          color: white;
          font-size: 70px;
          line-height: 55px;
        }
        .gui {
          font-size: 100px;
          margin-top: -50px;
          margin-left: 50px;
          color: #feba45;
        }
        p {
          color: white;
          font-size: 20px;
          max-width: 400px;
          padding-top: 20px;
        }
        @media (max-width: 1000px) {
          width: 100%;
          padding-bottom: 40px;

          p {
            max-width: none;
          }
        }
      }
    }
    // Olá programador(a) do futuro. A margin de baixo dessas imagens tá nos styles da galeria lá em cima
    // foi o jeito que achei :D
    // Adeus

    .chef-images {
      position: absolute;
      margin: 0 auto;
      top: 95%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      .chef-image {
        img {
          object-fit: cover;
        }
      }
      .horizontal {
        img {
          height: 300px;
        }
      }
      .vertical {
        img {
          height: 500px;
          max-width: 300px;
        }
      }
      .chef-images-s1 {
        display: flex;
        align-items: center;
        justify-content: center;
        #chef-image1 {
          margin-right: 30px;
        }
      }
      #chef-image3 {
        margin-top: -200px;
      }
      @media (max-width: 800px) {
        padding-top: 30px;
        .horizontal {
          img {
            width: 100%;
          }
        }
        .chef-images-s1 {
          flex-direction: column;
        }
        #chef-image2 {
          margin-top: -50px;
        }
      }
    }
  }
`;

export const Visite = styled.section`
  height: fit-content;
  position: relative;

  p {
    position: absolute;
    top: 110px;
    left: 110px;
    line-height: 65px;
    z-index: 50;
    font-family: 'Abril Fatface', cursive;
    color: #fff;
    font-size: 69px;

    @media screen and (max-width: 1020px) {
      top: 139px;
      left: 110px;
      line-height: 37px;
      font-size: 35px;
    }

    @media screen and (max-width: 870px) {
      font-size: 21px;
      top: 37px;
      left: 15px;
      line-height: 22px;
    }
    &:nth-child(2) {
      top: 170px;
      @media screen and (max-width: 870px) {
        top: 56px;
      }

      &::after {
        content: '';
        position: absolute;
        height: 68px;
        width: 545px;
        top: 18px;
        left: 541px;
        z-index: -1;
        transform: translate(-108%, 19%);
        background: #feba45;

        @media screen and (max-width: 1020px) {
          height: 37px;
          width: 250px;
          top: 11px;
          left: 259px;
        }

        @media screen and (max-width: 870px) {
          height: 19px;
          width: 158px;
          transform: translate(-167%, 17%);
        }
      }
    }
  }

  .slick-dots {
    bottom: 33px;
    z-index: 20;
  }

  .slick-dots li {
    margin-right: 30px;

    @media screen and (max-width: 870px) {
      margin-right: 10px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  .slick-dots li button:before {
    font-size: 22px;

    @media screen and (max-width: 870px) {
      font-size: 14px;
    }
  }

  .slick-dots li.slick-active button:before {
    color: #feba45;
  }
`;

export const Localizacao = styled.section`
  height: 1080px;
  overflow: hidden;

  @media screen and (max-width: 870px) {
    height: auto;
  }
  .agendar {
    font-size: 1.4rem;
  }

  p {
    text-align: center;
    margin-top: 50px;
    color: #281627;
    @media screen and (max-width: 870px) {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .horarios {
    p {
      font-size: 1.4rem;

      span {
        font-weight: bold;
      }
    }
  }

  .texts {
    width: 900px;
    margin: 0 auto 100px auto;
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 870px) {
      display: inherit;
      width: inherit;
      margin: 0 auto 80px auto;
    }

    p {
      font-family: 'Abril Fatface', cursive;
      color: #281627;

      &:first-of-type {
        font-size: 50px;
        @media screen and (max-width: 870px) {
          font-size: 39px;
        }
      }
      &:last-of-type {
        margin-top: 62px;
        font-size: 30px;

        span {
          padding-bottom: 5px;
          border-bottom: 5px solid #281627;
        }
      }

      span {
        font-size: 25px;
        margin-right: 10px;

        @media screen and (max-width: 870px) {
          font-size: 17px;
        }
      }
    }
  }

  .map-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 870px) {
      display: inherit;
    }
    .left-text {
      padding: 0 50px 0 150px;

      @media screen and (max-width: 870px) {
        padding: 0 0 50px 0;
        text-align: center;
      }
      h1 {
        font-size: 120px;
        line-height: 110px;
        margin-bottom: 0;
        color: #281627;
        @media screen and (max-width: 870px) {
          padding: 0 30px;
          font-size: 60px;
          line-height: 50px;
        }
      }

      p {
        font-size: 20px;

        @media screen and (max-width: 870px) {
          width: initial;
        }
      }
    }

    .map {
      iframe {
        max-width: 100%;
        @media screen and (max-width: 870px) {
          width: 100%;
        }
      }
    }
  }
`;

export const Contato = styled.section`
  height: 1080px;
  margin-top: -30px;
  z-index: 0;

  .max-holder {
    background: #281627;
    height: 100%;

    @media screen and (max-width: 870px) {
      height: 1200px;
    }

    .grid-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-top: 250px;
      justify-items: center;

      @media screen and (max-width: 870px) {
        grid-template-columns: inherit;
        padding-top: 55px;
      }

      h1 {
        font-size: 80px;
        color: #fff;
        line-height: 73px;
        @media screen and (max-width: 1320px) {
          font-size: 60px;
          line-height: 60px;
        }
        @media screen and (max-width: 870px) {
          padding: 50px 30px;
        }
      }

      .form-content {
        width: 600px;
        margin: auto;
        padding-right: 50px;
        @media screen and (max-width: 870px) {
          width: inherit;
          padding: 0 30px;
        }

        form {
          margin-top: 30px;
          transition: all ease-in-out 4s;

          span {
            color: #fafafa;
          }
          label {
            color: #fff;
            font-weight: 700;
            letter-spacing: 1px;
            font-size: 21px;
          }

          input {
            width: 100%;
            height: 35px;
            background: transparent;
            border: none;
            outline: none;
            color: #fff;
            border-bottom: 1px solid #feba45;
            font-size: 18px;
            margin-bottom: 40px;

            ::placeholder {
              color: lightgrey;
              opacity: 0.7;
            }
          }

          textarea {
            width: 100%;
            height: 100px;
            padding: 30px;
            background: transparent;
            border: none;
            outline: none;
            color: #fff;
            border-bottom: 1px solid #feba45;
            font-size: 14px;
            margin-bottom: 10px;
            resize: none;
          }
          .btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;
            padding-bottom: 32px;

            .button-submit {
              font-family: 'Abril Fatface', cursive;
              font-size: 20px;
              letter-spacing: 2px;
              background: #feba45;
              width: 150px;
              padding: 0;
              color: #fff;
              border-color: transparent;
              font-weight: bold;
              height: 45px;
              cursor: pointer;
              margin-bottom: 0;
              margin-top: 10px;
              transition: all ease 1s;

              &:hover {
                background: #ffc258;
              }
            }
          }
        }
      }
    }
  }
`;

export const Footer = styled.section`
  /* display: flex; */
  height: 100px;
  position: relative;
  /* justify-content: flex-end; */

  .grid-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 178px;

    @media screen and (max-width: 870px) {
      grid-template-columns: inherit;
    }
    .queijo {
      img {
        position: absolute;
        left: 0;
        top: -65%;
        max-width: 100%;
        width: 530px;

        @media screen and (max-width: 870px) {
          position: inherit;
        }
      }
    }
    p {
      font-family: 'Abril Fatface', cursive;
      text-align: end;
      padding: 70px 40px 5px 40px;
      img {
        padding: 0 10px;
      }
      a {
        color: #000;
      }
      @media screen and (max-width: 870px) {
        text-align: center;
        padding: 40px 0 6px 0;
      }

      &:last-of-type {
        padding: 3px 22px;
        margin-top: 50px;
        font-size: 14px;

        @media screen and (max-width: 870px) {
          padding: 10px 4px;
        }
      }
    }
  }
`;
